import React, { useState } from 'react'
import { Button, Divider, Space } from 'antd'
import { Link, graphql } from 'gatsby'
import Container from '../components/Landing/Container'
import { EditOutlined, PaperClipOutlined, FileDoneOutlined } from '@ant-design/icons'
import { BookDemoModal } from '../components/Landing/BookDemoModal'
import FeatureCarousel from '../components/Landing/FeatureCarousel'
import { isMobile } from 'react-device-detect'
import { getStoredAuthToken } from '../utils/auth-token'
import Img from 'gatsby-image'
import SEO from '../components/seo'

function LandingPage({ data }) {
    const [demoModalVisible, setDemoModalVisible] = useState(false)
    const [demoRequested, setDemoRequested] = useState(false)
    const [authToken] = useState(getStoredAuthToken())

    return (
        <Container logoImage={data.logoImage} setCookieValue={() => {}} pathName="index">
            <SEO title="Home" />
            <div className="top-landing-container">
                <div style={{ width: 500, marginBottom: 100 }}>
                    <h1 style={{ fontSize: isMobile ? 20 : 30 }}>
                        Gør det enkelt i hverdagen,
                        <br />
                        at følge Ligningsloven § 33A
                    </h1>
                    <p style={{ maxWidth: 500 }}>
                        Føler du dig overbevist om, at du kan få godkendt din skattelempelse?
                        <br />
                        Med ll33a.dk har du altid et realistisk overblik over, om du
                        <br />
                        opfylder betingelserne - lige ved hånden.
                    </p>
                    <Space>
                        <a href={(!!authToken ? '/app' : '/signup')}>
                            <Button type="primary">{!!authToken ? 'Login' : 'Prøv gratis'}</Button>
                        </a>
                        <Button
                            onClick={() => {
                                setDemoModalVisible(true)
                            }}
                            disabled={demoRequested}
                        >
                            Book demo
                        </Button>
                    </Space>
                </div>
                <Img
                    title="App Illustration"
                    alt="App illustration"
                    className="landing-app-illustration"
                    fluid={data.appIllustrationImage.childImageSharp.fluid}
                />
            </div>
            <Divider />
            <div
                style={{
                    margin: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Img
                    title="Report Example"
                    alt="Report Example"
                    className="landing-app-illustration"
                    fluid={data.pcReportExImage.childImageSharp.fluid}
                />
                <div style={{ width: 500, marginBottom: 100, marginTop: 30 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 15fr' }}>
                        <div />
                        <h1 style={{ fontSize: 30 }}>Hvad kan ll33a.dk gøre for dig?</h1>
                        <EditOutlined />
                        <span>
                            <p style={{ fontWeight: 'bold' }}>
                                Automatisk beregning af 42-dages-reglen
                            </p>
                            <p>
                                ll33a.dk kan altid fortælle dig hvor mange dage du har brugt i DK og
                                hvor mange dage du har til rådighed, når du planlægger fremtidig
                                ferie i DK. Det er nemt at få overblikket på grafen.
                            </p>
                        </span>
                        <PaperClipOutlined />
                        <span>
                            <p style={{ fontWeight: 'bold' }}>Altid styr på din dokumentation</p>
                            <p>
                                Det er slut med bortkomne bilag. ll33a.dk har altid overblik over
                                bilag som du har overført, og blanke dage du skal følge op på.
                            </p>
                        </span>
                        <FileDoneOutlined />
                        <span>
                            <p style={{ fontWeight: 'bold' }}>
                                Aflever nemt din data til Skattestyrelsen
                            </p>
                            <p>
                                Når du modtager materiale indkaldelse fra Skattestyrelsen...
                                <br />
                                ...ll33a.dk kompilerer automatisk skatterapporter for et givent
                                skatte år, på bagrund af dine registrerede dage i DK, samt uploadede
                                dokumentation.
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div
                style={{
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    maxWidth: '800px',
                    padding: 10,
                }}
            >
                <h1 style={{ fontSize: 30 }}>Alle funktioner, du skal bruge - samlet i én app</h1>
                <p style={{ fontSize: 14 }}>
                    I hverdagen skal du ikke gøre andet end:
                    <br />
                    - Upload dit “bevis” på; at du opholder dig i udlandet eller at du rejser ind
                    eller ud af DK.
                    <br />- Indsæt de dage i kalenderen hvor du er på ferie el. lign. i Danmark.
                </p>
                <Img
                    title="Calendar menus"
                    alt="Calendar menus"
                    className="landing-app-illustration"
                    fluid={data.calendarMenusImage.childImageSharp.fluid}
                />
            </div>
            <Divider />
            <div
                style={{
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    maxWidth: '800px',
                    padding: 10,
                }}
            >
                <h1 style={{ fontSize: 30 }}>
                    Team op med din revisor direkte gennem platformen og slip for besværet med at
                    sende bilag.
                </h1>
                <p style={{ fontSize: 18 }}>
                    Giv din revisor adgang til dine skatterapporter og spar både dig selv og din
                    revisor for dyrebar tid.
                </p>
                <Img
                    title="Team Revisor"
                    alt="Team op med din revisor"
                    className="landing-app-illustration"
                    fluid={data.teamUpImage.childImageSharp.fluid}
                />
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    backgroundColor: '#D7E6F5',
                    padding: 10,
                }}
            >
                <Divider style={{ opacity: 0 }} />
                <h1 style={{ fontSize: 40 }}>Prøv hvor nemt det er helt gratis i én måned</h1>
                <p style={{ fontSize: 18 }}>
                    Når du ønsker at fortsætte koster det 349,- kr. / 6 måneder
                </p>
                <Link to="/signup/">
                    <Button type="primary">Prøv gratis</Button>
                </Link>
                <Divider style={{ opacity: 0 }} />
            </div>
            <div
                style={{
                    margin: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <div style={{ width: 500, marginBottom: 100 }}>
                    <h1 style={{ fontSize: 30 }}>Installer appen på din telefon</h1>
                    <p style={{ maxWidth: 400 }}>
                        Gå til menuen på mobil-browseren og tryk tilføj til hjemmeskærm for at
                        downloade appen til din smartphone. Sørg for at du er på denne side når du
                        gør det.
                    </p>
                </div>
                <Img
                    title="Install app"
                    alt="Install app"
                    className="landing-app-illustration"
                    fluid={data.installImage.childImageSharp.fluid}
                />
            </div>
            <Divider />
            <div style={{ margin: 10 }}>
                <FeatureCarousel data={data} />
            </div>
            <BookDemoModal
                visible={demoModalVisible && !demoRequested}
                setVisible={setDemoModalVisible}
                setDemoRequested={setDemoRequested}
            />
        </Container>
    )
}

export default LandingPage

export const query = graphql`
    query {
        appIllustrationImage: file(relativePath: { eq: "app-illustration.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        pcReportExImage: file(relativePath: { eq: "computer-report-view.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        calendarMenusImage: file(relativePath: { eq: "calendar-menus.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        teamUpImage: file(relativePath: { eq: "team-up.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        installImage: file(relativePath: { eq: "install-phone-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        graphIphoneImage: file(relativePath: { eq: "graph-iphone.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        docExImage: file(relativePath: { eq: "doc-ex.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        blReportImage: file(relativePath: { eq: "bl-report.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`
