import { message, Modal } from 'antd'
import React from 'react'
import { httpGet } from '../../services/http'
import BookDemoForm from './bookDemo.form'

export interface IBookDemoModal {
    visible: boolean
    setVisible: (input: boolean) => void
    setDemoRequested: (input: boolean) => void
}

export function BookDemoModal({
    visible,
    setVisible,
    setDemoRequested,
}: IBookDemoModal) {
    const close = () => {
        setVisible(false)
    }
    const submit = async (values: any) => {
        try {
            await httpGet(`/landing/status?name=${values.name}&email=${values.email}&phone=${values.phone}`)
            setDemoRequested(true)
            message.success('Requested demo! We will be in touch :)', 5)
        } catch (error) {
            message.error('something went wrong')
        }
        close()
    }

    return (
        <Modal visible={visible} onCancel={close} onOk={close} destroyOnClose footer={[]}>
            <div className="change-password-modal">
                <BookDemoForm onSubmit={submit} />
            </div>
        </Modal>
    )
}
