import { Carousel, Menu } from 'antd'
import React, { useRef, useState } from 'react'
import { CarryOutOutlined, AuditOutlined } from '@ant-design/icons'
import Img from 'gatsby-image'

interface ICarouselRef {
    goTo: any
}

const FeatureCarousel = ({ data }) => {
    const [currentTab, setCurrentTab] = useState<string>('0')
    const [autoplay, setAutoplay] = useState<boolean>(true)
    var carouselRef = useRef<ICarouselRef>()

    const changeTabManually = (v) => {
        setAutoplay(false)
        changeTab(v)
    }

    const changeTab = (v) => {
        setCurrentTab(String(v.key))
        carouselRef.current.goTo(Number(v.key))
    }

    return (
        <div>
            <div className="content-row">
                <div className="feature-comp-container">
                    <Menu
                        mode="horizontal"
                        selectedKeys={[currentTab]}
                        onClick={changeTabManually}
                        style={{ background: 'rgba(0,0,0,0)', color: 'rgba(215 , 230, 245, 0.6)' }}
                    >
                        <Menu.Item
                            className="feature-menu-item"
                            key="0"
                            icon={<CarryOutOutlined />}
                        >
                            Validering
                        </Menu.Item>
                        <Menu.Item className="feature-menu-item" key="1" icon={<AuditOutlined />}>
                            Dokumentation
                        </Menu.Item>
                        <Menu.Item className="feature-menu-item" key="2" icon={<AuditOutlined />}>
                            Rapportering
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
            <div className="content-row">
                <div className="feature-comp-container">
                    <Carousel
                        autoplay={autoplay}
                        ref={(ref) => {
                            carouselRef.current = ref
                        }}
                        afterChange={(n) => {
                            changeTab({ key: n })
                        }}
                    >
                        <div className="carousel-item">
                            <h1 className="carousel-item-text">
                                Automatisk udregning af 42-dages-reglen
                            </h1>
                            <p className="carousel-item-text">
                                Indsæt dine Danmarks dage i kalenderen og ll33a.dk vil beregne hvor
                                godt du overholder 42-dages-reglen.
                                <br />
                                Indsæt også dine fremtidsplaner i kalenderen så du kan være på
                                forkant med loven.
                            </p>
                            <div className="guide-elm" style={{marginBottom: 20}}>
                                <Img
                                    title="Graph iphone"
                                    alt="Graph iphone"
                                    className="iphone-image"
                                    fluid={data.graphIphoneImage.childImageSharp.fluid}
                                />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <h1 className="carousel-item-text">Hold styr på alle dine bilag</h1>
                            <p className="carousel-item-text">
                                På kalendersiden kan du overføre bilag til hver enkelt dag. Dine
                                bilag vil automatisk blive sat ind i dine skatterapporter og ikke
                                mindst blive opbevaret sikkert på vores servere. Vi er kompatible
                                med det hele, kamera-rullen, scanner-app,
                                iCloud/GoogleDrive/OneDrive, PC/Mac filsystem osv.
                            </p>
                            <div className="guide-elm">
                                <Img
                                    title="Doc example"
                                    alt="Doc example"
                                    className="iphone-image"
                                    fluid={data.docExImage.childImageSharp.fluid}
                                />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <h1 className="carousel-item-text">Compliance Reports / Skatterapporter</h1>
                            <p className="carousel-item-text">
                                Med "Compliance Reports" binder du al din data sammen. Det er disse
                                der i sidste ende udgør din dokumentation til Skattestyrelsen.
                                Skatterapporterne ("Compliance Reports") på ll33a.dk er tilpasset
                                skatteåret. Dvs. når du opretter et skatteår, "Tax Year", vil der
                                automatisk blive genereret rapporter som indeholder al relevant data
                                for det pågældende skatteår.
                            </p>
                            <div className="guide-elm">
                                <Img
                                    title="Report example"
                                    alt="Report example"
                                    className="iphone-image"
                                    fluid={data.blReportImage.childImageSharp.fluid}
                                />
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default FeatureCarousel
