import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'

interface IBookDemoFormRender {
    onSubmit: (values: any) => void
}

export const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
}
export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
}

const BookDemoForm = ({ onSubmit }: IBookDemoFormRender) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const onFinish = async (values: any) => {
        setLoading(true)
        await onSubmit(values)
        setLoading(false)
    }

    return (
        <>
            <Form
                {...formItemLayout}
                form={form}
                name="requestDemo"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item name="name" label="Navn" hasFeedback>
                    <Input
                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Dit navn"
                    />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    name="email"
                    label="E-mail"
                    rules={[
                        { required: true, message: 'Please enter your email!' },
                        { type: 'email', message: 'Not a valid email' },
                    ]}
                    className="signin-input"
                >
                    <Input
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Din E-mail"
                    />
                </Form.Item>
                <Form.Item name="phone" label="Telefon" hasFeedback>
                    <Input
                        prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Dit telefon nr."
                    />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Indsend
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default BookDemoForm
